export class ResponseError {
  private status: number;
  private message: string;

  constructor (status: number, message: string) {
    this.status = status;
    this.message = message;
  }

  public getMessage(): string {
    return this.message;
  }

  public setMessage(message: string): void {
    this.message = message;
  }
}
