import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ResponseError } from './models/response-error';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthService) {}

  private handleAuthError(err: HttpErrorResponse) {
    const res = new ResponseError(err.status, '');
    if (err.status === 401 && this.router.url != '/auth/login') {
      localStorage.clear();
      this.router.navigateByUrl('/auth/login');
      res.setMessage(err.error.message);
    }
    else if (err.status === 422) {
      let message = '';
      const errors = err.error.errors;
      Object.keys(errors).forEach(k => message += errors[k] + '.\n');
      res.setMessage(message);
    }
    else if ('message' in err.error) {
      res.setMessage(err.error.message);
    }
    return throwError(res);
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler) {
    const token = this.authService.getAuthToken();
    if (token != null) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next
      .handle(request)
      .pipe(catchError((err) => this.handleAuthError(err)));
  }
}
