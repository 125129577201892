import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { Usuario } from 'src/app/models/usuario';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userStorageKey: string = '_auth_user';
  private tokenStorageKey: string = '_auth_token';
  private apiUrl: string = environment.apiUrl;
  private userSubject: BehaviorSubject<Usuario>;

  constructor(
    private http: HttpClient
  ) {
    const user: Usuario = JSON.parse(localStorage.getItem(this.userStorageKey));
    this.userSubject = new BehaviorSubject(user);
  }

  public isLogged(): boolean {
    return localStorage.getItem(this.userStorageKey) !== null;
  }

  public getUser(): Usuario {
    return this.userSubject.value;
  }

  public getAuthToken(): string {
    return localStorage.getItem(this.tokenStorageKey);
  }

  public authenticate(email: string, password: string) {
    type AuthResult = { user: Usuario, access_token: string };
    const url = `${this.apiUrl}/auth/login`;
    const data = { email, password };
    return this.http.post<AuthResult>(url, data)
      .pipe(
        map(res => {
          localStorage.setItem(this.tokenStorageKey, res.access_token);
          localStorage.setItem(this.userStorageKey, JSON.stringify(res.user));
          this.userSubject.next(res.user);
          return res.user;
        })
      );
  }
}
